.Zone-dropdown {
  .timezone-border {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #69717e;
    display: flex;
    flex-direction: row;
  }
  .dropdown-content {
    width: 120px !important;
  }
}
input {
  color: white;
  :focus-visible {
    border: none;
  }
}
input:focus-visible {
  outline: 2px solid var(--primary);
  border-radius: 3px;
}

.global-header .dropdown-wrapper main {
  padding: 0px;
}
.itemshover {
  :hover {
    background-color: rgb(28, 32, 41);
  }
}
