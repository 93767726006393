.page-with-tabs {
  padding-left: 0;
  padding-right: 0;
  .page-with-tabs-hero-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 300px;
    background-size: cover !important;
    background-position: center center !important;
  }

  .page-with-tabs-header__less-margin {
    margin: 20px 0px -20px;
  }

  .page-with-tabs-header {
    display: flex;
    margin: 40px 0px 60px;

    .page-with-tabs-info {
      display: flex;
      flex-direction: column;
      margin-left: 22px;
      justify-content: center;

      h1 {
        text-transform: none;
        margin: 0px;
        padding: 6px 0px 12px;
        font-size: 32px;
        line-height: 39px;
      }
    }
  }

  .page-with-tabs-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 111px;
    height: 111px;

    .image-cropped {
      border-radius: 100% !important; //Added once again because it imapct all over the pages
      width: 100%;
      object-fit: contain;
      height: auto;
      // border-radius: 100%;
    }

    .image-notCropped {
      width: 100%;
      object-fit: contain;
      height: auto;
      // border-radius: 100%;
    }
  }
}
