.edit-image-modal {
  .edit-image-controls {
    padding: 20px 20px 10px 20px;
    text-align: center;
    display: flex;

    input {
      width: 100%;
      margin: -5px 5px 0 5px;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      color: white;
      cursor: pointer;
      padding: 0;
      width: 40px;
    }
  }

  .save-buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
}
.reuploadbutton {
  position: absolute;
  top: 1%;
  right: 1%;
  width: fit-content;
  height: 38px;
  background: #68717e 0% 0% no-repeat padding-box;
  border-radius: 2px;
}
.image-dropzone {
  border: 3px dashed rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  padding: 30px 25px;
  text-align: center;
  color: #999;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.1rem;
  font-size: 0.8rem;
  cursor: pointer;
}
.image-dropzoneonhover {
  margin: 20px;

  :hover {
    background-color: #1b2837;
    border: 3px dashed var(--primary);
  }
}
